import gql from 'graphql-tag';
import { isNil } from '~utils';
import { apolloClient as apollo } from '@/vue-apollo';

export const getExpenseItems = async () => {
	try {
		const {
			data: { GetProjectUserExpenseDetail }
		} = await apollo.query({
			query: gql`
				query {
					GetProjectUserExpenseDetail {
						id
						createdAt
						updatedAt
						expenseItem {
							id
							validated
							createdAt
							updatedAt
							expenseId
							description
							date
							type
							department
							departmentName
							amount
							amount
							kgCoTwo
							fromLocation
							toLocation
							km
							amountTotal
							picture
							amountVat
							supplierName
							categoryName
							paiementTypeName
							encoderId
							function
							functionName
							encoder {
								id
								name
								email
								firstName
								fullName
							}
							user {
								id
								name
								firstName
							}
						}
						expense {
							id
							createdAt
							updatedAt
							description
							type
							department
							function
							functionName
						}
						co2
					}
				}
			`,
			variables: {},
			fetchPolicy: 'no-cache'
		});
		return GetProjectUserExpenseDetail;
	} catch (e) {
		console.log({ e });
	}
};

export const getExpenseItemsNotSubmitted = async (activateCrewAccessRestriction) => {
	try {
		const {
			data: { GetProjectUserExpenseDetail }
		} = await apollo.query({
			query: gql`
				query ($Filter: AdvancedGlobalFilterInput) {
					GetProjectUserExpenseDetail(Filter: $Filter) {
						id
						createdAt
						updatedAt
						expenseItem {
							id
							validated
							createdAt
							updatedAt
							expenseId
							description
							date
							type
							department
							departmentName
							amount
							kgCoTwo
							fromLocation
							toLocation
							km
							amountTotal
							picture
							images
							amountVat
							comment
							supplierName
							category
							coTwoCategory
							paiementType
							subCategory
							subCategoryName
							supplierId
							categoryName
							paiementTypeName
							encoderId
							function
							functionName
							encoder {
								id
								name
								email
								firstName
								fullName
							}
							user {
								id
								name
								firstName
							}
						}
						expense {
							id
							createdAt
							updatedAt
							description
							type
							department
						}
						co2
					}
				}
			`,
			variables: {
				Filter: {
					notSubmitted: true,
					activateCrewAccessRestriction: activateCrewAccessRestriction
				}
			},
			fetchPolicy: 'no-cache'
		});
		return GetProjectUserExpenseDetail;
	} catch (e) {
		console.log({ e });
	}
};

export const getExpenseItemsSubmitted = async (activateCrewAccessRestriction) => {
	try {
		const {
			data: { GetProjectUserExpenseSheet }
		} = await apollo.query({
			query: gql`
				query ($Filter: AdvancedGlobalFilterInput) {
					GetProjectUserExpenseSheet(Filter: $Filter) {
						id
						createdAt
						updatedAt
						expense {
							id
							validated
							createdAt
							updatedAt
							description
							date
							type
							department
							departmentName
							amount
							kgCoTwo
							amount
							amountTotal
							amountVat
							comment
							supplierName
							supplierId
							description
							function
							functionName
							user {
								id
								name
								firstName
							}
							encoderId
							encoder {
								id
								name
								fullName
								firstName
							}
						}
						co2
					}
				}
			`,
			variables: {
				Filter: {
					submitted: true,
					activateCrewAccessRestriction: activateCrewAccessRestriction
				}
			},
			fetchPolicy: 'no-cache'
		});
		return GetProjectUserExpenseSheet;
	} catch (e) {
		console.log({ e });
	}
};

export const deleteExpenseEfc = async (id) => {
	if (isNil(id)) return;
	const mutation = gql`
		mutation ($ExpenseItemId: ID!) {
			DelExpenseItem(ExpenseItemId: $ExpenseItemId) {
				id
			}
		}
	`;
	let { data } = await apollo
		.mutate({
			mutation,
			variables: { ExpenseItemId: parseInt(id) }
		})
		.then((result) => {
			return result;
		})
		.catch((err) => console.log({ err }));
	return data.DelExpenseItem;
};

export const AddUpdExpenseItem = async (item) => {
	try {
		let result = await UpdatedExpenseItemEfc(item);
		return result;
	} catch (error) {
		throw new error();
	}
};

export const AddExpenseItemEfc = async (item) => {
	try {
		const mutation = gql`
			mutation ($AddedExpenseItem: ExpenseItemInput!) {
				NewExpenseItem(AddedExpenseItem: $AddedExpenseItem) {
					department
					description
					paiementType
					paiementTypeName
					coTwoCategory
					subCategory
					type
					date
					id
					amountTotal
					amountVat
					fromLocation
					toLocation
					amount
					category
					allItemsTotal
					supplierName
					categoryName
					km
					kgCoTwo
					supplierId
				}
			}
		`;
		const {
			data: { NewExpenseItem }
		} = await apollo.mutate({
			mutation,
			variables: { AddedExpenseItem: item }
		});
		return NewExpenseItem;
	} catch (error) {
		throw new error();
	}
};

export const UpdatedExpenseItemEfc = async (item) => {
	const mutation = gql`
		mutation ($ExpenseItemId: ID!, $UpdatedExpenseItem: ExpenseItemInput!, $UserId: ID) {
			UpdExpenseItem(ExpenseItemId: $ExpenseItemId, UpdatedExpenseItem: $UpdatedExpenseItem, UserId: $UserId) {
				id
			}
		}
	`;
	const {
		data: { UpdExpenseItem }
	} = await apollo.mutate({
		mutation,
		variables: {
			ExpenseItemId: +item.id,
			UpdatedExpenseItem: {
				expenseId: item.expenseId,
				type: item.type,
				amount: item.amount,
				amountTotal: item.amountTotal,
				amountVat: item.amountVat,
				category: item.category,
				categoryName: item.categoryName,
				coTwoCategory: item.coTwoCategory,
				comment: item.comment,
				department: item.department,
				departmentName: item.departmentName,
				date: item.date,
				description: item.description,
				fromLocation: item.fromLocation,
				images: item.images,
				kgCoTwo: item.kgCoTwo,
				km: item.km,
				paiementType: item.paiementType,
				paiementTypeName: item.paiementTypeName,
				picture: item.image,
				subCategory: item.subCategory,
				supplierId: item.supplierId,
				toLocation: item.toLocation,
				validated: item.validated
			},
			UserId: +item.user.id
		}
	});

	return UpdExpenseItem;
};
