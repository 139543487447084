var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "errorContainer",
    { attrs: { error: _vm.erreur, warning: _vm.warning } },
    [
      _c("custom-loading-planning", {
        attrs: {
          loading: _vm.isLoading,
          label: _vm.FormMSG(37, "Prepare data of expense entry for crew"),
        },
      }),
      _vm.$screen.width <= 576
        ? _c(
            "div",
            { staticClass: "animated fadeIn" },
            [
              _c("div", { staticClass: "container-mobile" }, [
                _c(
                  "div",
                  {
                    class: `my-expense-mobile ${
                      _vm.$screen.width > 576 ? "spacing-pad" : ""
                    }`,
                  },
                  [
                    _c("div", { staticClass: "title" }, [
                      _c(
                        "h1",
                        {
                          staticClass: "main-page-title",
                          class: { "is-pwa": _vm.$isPwa() },
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t\t\t" +
                              _vm._s(_vm.FormMSG(90, "Expenses for crew")) +
                              "\n\t\t\t\t\t\t"
                          ),
                        ]
                      ),
                    ]),
                    _c("div", { staticClass: "d-flex flex-column position" }, [
                      _c(
                        "div",
                        {
                          staticClass: "my-expense-actions border-style",
                          class: { "pt-16": _vm.$isPwa() },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-content-start title-add",
                            },
                            [_vm._v(_vm._s(_vm.FormMSG(2, "Add Expense")))]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-content-center wapper",
                            },
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "btn-action gap-right",
                                  attrs: {
                                    to: "/myexpenses/1/n/2?efc=1",
                                    tag: "div",
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: "svgs/mileage.svg",
                                      alt: "Mileage",
                                    },
                                  }),
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.FormMSG(3, "Mileage"))),
                                  ]),
                                ]
                              ),
                              _c(
                                "router-link",
                                {
                                  staticClass: "btn-action gap-right",
                                  attrs: {
                                    to: "/myexpenses/2/n/0?efc=2",
                                    tag: "div",
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: "svgs/expense.svg",
                                      alt: "Ticket",
                                    },
                                  }),
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.FormMSG(4, "Ticket"))),
                                  ]),
                                ]
                              ),
                              !_vm.isFilmSingle
                                ? _c(
                                    "router-link",
                                    {
                                      staticClass: "btn-action",
                                      attrs: {
                                        to: "/myexpenses/3/n/1?efc=3",
                                        tag: "div",
                                      },
                                    },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: "svgs/invoice.svg",
                                          alt: "Invoice",
                                        },
                                      }),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.FormMSG(5, "Invoice"))
                                        ),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]),
                    _c("div", [
                      _c("div", { staticClass: "my-expense-status-actions" }, [
                        _c(
                          "div",
                          { staticClass: "status-actions" },
                          [
                            _c(
                              "b-button-group",
                              [
                                _c(
                                  "b-button",
                                  {
                                    class: `not-submitted${
                                      _vm.notSubmittedState ? "" : " not-active"
                                    }`,
                                    attrs: {
                                      pressed: _vm.notSubmittedState,
                                      "aria-pressed": "true",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.setStatusExpenseDefault(0)
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.firstTabCaptionMobile))]
                                ),
                                _c(
                                  "b-button",
                                  {
                                    class: `submitted${
                                      _vm.submittedState ? "" : " not-active"
                                    }`,
                                    attrs: {
                                      pressed: _vm.submittedState,
                                      "aria-pressed": "true",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.setStatusExpenseDefault(1)
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.secondTabCaptionMobile))]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm.notSubmittedState &&
                        _vm.notSubmittedCopyIds.length > 0
                          ? _c(
                              "div",
                              {
                                staticClass: "check-actions cursor-pointer",
                                on: { click: _vm.switchCheckAll },
                              },
                              [
                                _vm.checkAll
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex justify-content-end align-items-center",
                                      },
                                      [
                                        _c("span", { staticClass: "pr-2" }, [
                                          _vm._v(_vm._s(_vm.FormMSG(6, "All"))),
                                        ]),
                                        _c("check-square", {
                                          attrs: { size: 16 },
                                        }),
                                      ],
                                      1
                                    )
                                  : _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex justify-content-end align-items-center",
                                      },
                                      [
                                        _c("span", { staticClass: "pr-2" }, [
                                          _vm._v(
                                            _vm._s(_vm.FormMSG(7, "None"))
                                          ),
                                        ]),
                                        _c("square", { attrs: { size: 16 } }),
                                      ],
                                      1
                                    ),
                              ]
                            )
                          : _vm._e(),
                      ]),
                      _vm.GetExpenseMobile.length > 0
                        ? _c(
                            "div",
                            { staticClass: "content pb-3" },
                            _vm._l(
                              _vm.GetExpenseMobile,
                              function (expense, index) {
                                return _c(
                                  "div",
                                  {
                                    key: expense.id + index,
                                    staticClass: "card-expense",
                                    attrs: {
                                      id: `${
                                        _vm.notSubmittedState &&
                                        parseInt(expense.validated, 10) === 0
                                          ? _vm.generateTextId(
                                              "card-expense-" + expense.id
                                            )
                                          : ""
                                      }`,
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "wrapper" }, [
                                      _c("div", { staticClass: "resume" }, [
                                        _vm.notSubmittedState &&
                                        parseInt(expense.validated, 10) === 0
                                          ? _c(
                                              "div",
                                              { staticClass: "first-col" },
                                              [
                                                _c("b-form-checkbox", {
                                                  attrs: {
                                                    size: "lg",
                                                    value: expense.id,
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      _vm.setSelectedItem(
                                                        $event,
                                                        expense,
                                                        _vm.generateTextId(
                                                          "card-expense-" +
                                                            expense.id
                                                        )
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value: _vm.notSubmittedIds,
                                                    callback: function ($$v) {
                                                      _vm.notSubmittedIds = $$v
                                                    },
                                                    expression:
                                                      "notSubmittedIds",
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "second-col",
                                            style: `width: ${
                                              _vm.notSubmittedState &&
                                              parseInt(
                                                expense.validated,
                                                10
                                              ) === 0
                                                ? "56.5%;"
                                                : "67.5%;"
                                            }`,
                                          },
                                          [
                                            _vm.showExpenseTitle(expense)
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass: "description",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                        _vm._s(
                                                          `${
                                                            expense.user.name +
                                                            " " +
                                                            expense.user
                                                              .firstName
                                                          }`
                                                        ) +
                                                        " - " +
                                                        _vm._s(
                                                          expense.description
                                                        ) +
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _c(
                                              "div",
                                              { staticClass: "title" },
                                              [_vm._v("#" + _vm._s(expense.id))]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "amount" },
                                              [
                                                _vm._v(
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                    _vm._s(
                                                      _vm.rendAmountTotal(
                                                        expense.amountTotal
                                                      )
                                                    ) +
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                ),
                                              ]
                                            ),
                                            _c("div", { staticClass: "date" }, [
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                  _vm._s(
                                                    _vm.dateToLiteral(
                                                      expense.date
                                                    )
                                                  ) +
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t"
                                              ),
                                            ]),
                                            expense.type !== 2
                                              ? _c(
                                                  "div",
                                                  { staticClass: "carbon" },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "icon" },
                                                      [
                                                        _c(
                                                          _vm.getLucideIcon(
                                                            _vm.ICONS.SPROUT
                                                              .name
                                                          ),
                                                          {
                                                            tag: "component",
                                                            attrs: {
                                                              color:
                                                                _vm.ICONS.SPROUT
                                                                  .color,
                                                              size: 14,
                                                            },
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "div",
                                                      { staticClass: "label" },
                                                      [
                                                        _vm._v(
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                            _vm._s(
                                                              _vm.rendCo2(
                                                                expense.kgCoTwo
                                                              )
                                                            )
                                                        ),
                                                        _c("span", [
                                                          _vm._v(" CO"),
                                                          _c("sub", [
                                                            _vm._v("2"),
                                                          ]),
                                                        ]),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              : _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "mileage-resume",
                                                  },
                                                  [
                                                    expense.fromLocation &&
                                                    expense.toLocation
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "details pt-1",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "first-col",
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "icon-rotate",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      _vm.getLucideIcon(
                                                                        _vm
                                                                          .ICONS
                                                                          .ARROWLEFTRIGHT
                                                                          .name
                                                                      ),
                                                                      {
                                                                        tag: "component",
                                                                        attrs: {
                                                                          color:
                                                                            _vm
                                                                              .ICONS
                                                                              .ARROWLEFTRIGHT
                                                                              .color,
                                                                          size: 16,
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "second-col",
                                                              },
                                                              [
                                                                _c("div", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      expense.fromLocation
                                                                    )
                                                                  ),
                                                                ]),
                                                                _c("div", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      expense.toLocation
                                                                    )
                                                                  ),
                                                                ]),
                                                              ]
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "details",
                                                        class: `${
                                                          !expense.km
                                                            ? ""
                                                            : "pt-2"
                                                        }`,
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "first-col",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "icon",
                                                              },
                                                              [
                                                                _c(
                                                                  _vm.getLucideIcon(
                                                                    _vm.ICONS
                                                                      .SPROUT
                                                                      .name
                                                                  ),
                                                                  {
                                                                    tag: "component",
                                                                    attrs: {
                                                                      color:
                                                                        _vm
                                                                          .ICONS
                                                                          .SPROUT
                                                                          .color,
                                                                      size: !expense.km
                                                                        ? 14
                                                                        : 16,
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "second-col",
                                                            class: `${
                                                              !expense.km
                                                                ? "spl-4"
                                                                : ""
                                                            }`,
                                                          },
                                                          [
                                                            expense.km
                                                              ? _c("div", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.recalculateDistanceByUnit(
                                                                        expense.km
                                                                      )
                                                                    ) +
                                                                      " " +
                                                                      _vm._s(
                                                                        _vm.distanceUnit
                                                                      )
                                                                  ),
                                                                ])
                                                              : _vm._e(),
                                                            _c(
                                                              "div",
                                                              {
                                                                style: `margin-bottom: ${
                                                                  expense.km
                                                                    ? "0"
                                                                    : "-1px"
                                                                }`,
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                    _vm._s(
                                                                      _vm.rendCo2(
                                                                        expense.kgCoTwo
                                                                      )
                                                                    ) +
                                                                    " CO"
                                                                ),
                                                                _c("sub", [
                                                                  _vm._v("2"),
                                                                ]),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "third-col" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "status-wrapper" },
                                              [
                                                !_vm.isFilmSingle
                                                  ? _c(
                                                      "div",
                                                      {
                                                        class: `status ${_vm.classStatus(
                                                          expense.validated
                                                        )}`,
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                            _vm._s(
                                                              _vm.getLabelStatus(
                                                                expense.validated
                                                              )
                                                            ) +
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _c(
                                                  "div",
                                                  {
                                                    class: `${
                                                      !_vm.isFilmSingle
                                                        ? "type mt-2"
                                                        : "type"
                                                    }`,
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      { staticClass: "icon" },
                                                      [
                                                        expense.type === 0
                                                          ? _c(
                                                              _vm.getLucideIcon(
                                                                _vm.ICONS.TICKET
                                                                  .name
                                                              ),
                                                              {
                                                                tag: "component",
                                                                attrs: {
                                                                  color:
                                                                    _vm.ICONS
                                                                      .TICKET
                                                                      .color,
                                                                  size: 16,
                                                                },
                                                              }
                                                            )
                                                          : _vm._e(),
                                                        expense.type === 1
                                                          ? _c(
                                                              _vm.getLucideIcon(
                                                                _vm.ICONS
                                                                  .FILE_TEXT
                                                                  .name
                                                              ),
                                                              {
                                                                tag: "component",
                                                                attrs: {
                                                                  color:
                                                                    _vm.ICONS
                                                                      .FILE_TEXT
                                                                      .color,
                                                                  size: 14,
                                                                },
                                                              }
                                                            )
                                                          : _vm._e(),
                                                        expense.type === 2
                                                          ? _c(
                                                              _vm.getLucideIcon(
                                                                _vm.ICONS.CAR
                                                                  .name
                                                              ),
                                                              {
                                                                tag: "component",
                                                                attrs: {
                                                                  color:
                                                                    _vm.ICONS
                                                                      .CAR
                                                                      .color,
                                                                  size: 16,
                                                                },
                                                              }
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass: "label",
                                                        style: `${
                                                          expense.type === 2
                                                            ? "margin-top: 2px;"
                                                            : ""
                                                        }`,
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.setType(
                                                              expense.type
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]),
                                    _c("div", { staticClass: "footer" }, [
                                      _c("div", { staticClass: "actions" }, [
                                        _vm.notSubmittedState &&
                                        expense.images.length > 0
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "first-col",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.showPicture(
                                                      expense.images
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "icon" },
                                                  [
                                                    _c(
                                                      _vm.getLucideIcon(
                                                        _vm.ICONS.PHOTO_MOBILE
                                                          .name
                                                      ),
                                                      {
                                                        tag: "component",
                                                        attrs: {
                                                          color:
                                                            _vm.ICONS
                                                              .PHOTO_MOBILE
                                                              .color,
                                                          size: 16,
                                                        },
                                                      }
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "label" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.FormMSG(
                                                          8,
                                                          "See Receipt"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "second-col",
                                            style: `width: ${
                                              (_vm.notSubmittedState &&
                                                expense.images.length === 0) ||
                                              _vm.submittedState
                                                ? "100%;"
                                                : "60%;"
                                            }`,
                                          },
                                          [
                                            _vm.notSubmittedState
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "cursor-pointer d-flex flex-row pr-3",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.editExpenseMobile(
                                                          expense
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "icon" },
                                                      [
                                                        _c(
                                                          _vm.getLucideIcon(
                                                            _vm.ICONS
                                                              .EDIT_MOBILE.name
                                                          ),
                                                          {
                                                            tag: "component",
                                                            attrs: {
                                                              color:
                                                                _vm.ICONS
                                                                  .EDIT_MOBILE
                                                                  .color,
                                                              size: 16,
                                                            },
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "div",
                                                      { staticClass: "label" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              9,
                                                              "Edit"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm.submittedState
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "cursor-pointer d-flex flex-row",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.viewExpenseDatailMobile(
                                                          expense,
                                                          _vm.isHome
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "icon" },
                                                      [
                                                        _c(
                                                          _vm.getLucideIcon(
                                                            _vm.ICONS.EYE.name
                                                          ),
                                                          {
                                                            tag: "component",
                                                            attrs: {
                                                              color:
                                                                _vm.ICONS.EYE
                                                                  .color,
                                                              size: 16,
                                                            },
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "div",
                                                      { staticClass: "label" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              10,
                                                              "View"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm.notSubmittedState
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "cursor-pointer d-flex flex-row",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.deleteExpenseMobile(
                                                          expense.id
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "icon" },
                                                      [
                                                        _c(
                                                          _vm.getLucideIcon(
                                                            _vm.ICONS
                                                              .TRASH_MOBILE.name
                                                          ),
                                                          {
                                                            tag: "component",
                                                            attrs: {
                                                              color:
                                                                _vm.ICONS
                                                                  .TRASH_MOBILE
                                                                  .color,
                                                              size: 16,
                                                            },
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "div",
                                                      { staticClass: "label" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              11,
                                                              "Delete"
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                      ]),
                                    ]),
                                  ]
                                )
                              }
                            ),
                            0
                          )
                        : _c("div", { staticClass: "empty-data" }, [
                            _vm._v(
                              "\n\t\t\t\t\t\t\t" +
                                _vm._s(_vm.FormMSG(12, "No expense found")) +
                                "\n\t\t\t\t\t\t"
                            ),
                          ]),
                    ]),
                    _vm.notSubmittedState && _vm.notSubmittedIds.length > 0
                      ? _c(
                          "b-row",
                          { staticClass: "footer-fixed" },
                          [
                            _c(
                              "b-col",
                              { attrs: { cols: "12" } },
                              [
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "mb-2",
                                    attrs: {
                                      block: "",
                                      size: "lg",
                                      variant: "primary",
                                    },
                                    on: {
                                      click:
                                        _vm.handleSendExpensesForValidationMobile,
                                    },
                                  },
                                  [
                                    !_vm.isFilmSingle
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.FormMSG(
                                                13,
                                                "Send Expenses for Validation"
                                              )
                                            )
                                          ),
                                        ])
                                      : _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.FormMSG(
                                                14,
                                                "Create expenses sheet"
                                              )
                                            )
                                          ),
                                        ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _c("title-expense-modal", {
                attrs: { "reinitialize-value": _vm.reinitializeTitle },
                on: {
                  "title-expense-modal:cancel": _vm.onCancelTitleModal,
                  "title-expense-modal:add": _vm.onAddTitleModal,
                },
                model: {
                  value: _vm.isOpenTitleModal,
                  callback: function ($$v) {
                    _vm.isOpenTitleModal = $$v
                  },
                  expression: "isOpenTitleModal",
                },
              }),
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "form" },
            [
              _c(
                "b-row",
                { attrs: { "align-h": "between" } },
                [
                  _c("b-col", { attrs: { lg: "4" } }, [
                    _c("h1", [
                      _vm._v(_vm._s(_vm.FormMSG(90, "Expenses for crew"))),
                    ]),
                  ]),
                  _c(
                    "b-col",
                    { staticClass: "text-right", attrs: { lg: "8" } },
                    [
                      _c(
                        "b-button-group",
                        [
                          _c(
                            "b-dropdown",
                            {
                              attrs: {
                                right: "",
                                text: _vm.FormMSG(16, "Create..."),
                                variant: "success mr-2",
                              },
                            },
                            [
                              _c(
                                "b-dropdown-item",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.openExpenseModal(2, true)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.FormMSG(17, "Mileage"))
                                  ),
                                ]
                              ),
                              _c(
                                "b-dropdown-item",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.openExpenseModal(0, true)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.FormMSG(18, "Ticket"))
                                  ),
                                ]
                              ),
                              _vm.project.useKlippaOcr
                                ? _c(
                                    "b-dropdown-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleCreateKlippaOcrOpenModal(
                                            {
                                              isMultipe: false,
                                              isSplitTva: true,
                                            }
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t" +
                                          _vm._s(
                                            _vm.FormMSG(
                                              40,
                                              "Create one ticket from pictures"
                                            )
                                          ) +
                                          "\n\t\t\t\t\t\t\t"
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm.project.useKlippaOcr
                                ? _c(
                                    "b-dropdown-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.handleCreateKlippaOcrOpenModal(
                                            { isMultipe: true }
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t" +
                                          _vm._s(
                                            _vm.FormMSG(
                                              41,
                                              "Create multiple ticket from pictures (one picture/ticket)"
                                            )
                                          ) +
                                          "\n\t\t\t\t\t\t\t"
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              !_vm.isFilmSingle
                                ? _c(
                                    "b-dropdown-item",
                                    {
                                      on: {
                                        click: function ($event) {
                                          return _vm.openExpenseModal(1, true)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.FormMSG(19, "Invoice"))
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.notSubmitedExpenses && _vm.submitedExpenses
                ? _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "12", xl: "12" } },
                        [
                          _c(
                            "b-card",
                            { attrs: { "no-body": "" } },
                            [
                              _c(
                                "b-tabs",
                                {
                                  attrs: { card: "" },
                                  model: {
                                    value: _vm.currentExpenseTab,
                                    callback: function ($$v) {
                                      _vm.currentExpenseTab = $$v
                                    },
                                    expression: "currentExpenseTab",
                                  },
                                },
                                [
                                  _c(
                                    "b-tab",
                                    { attrs: { title: _vm.tabOneCaption() } },
                                    [
                                      _c(
                                        "b-card-text",
                                        [
                                          _c(
                                            "b-row",
                                            [
                                              _c(
                                                "b-col",
                                                {
                                                  staticClass: "pb-3",
                                                  attrs: { cols: "6" },
                                                },
                                                [
                                                  _c(
                                                    "b-input-group",
                                                    [
                                                      _c("b-form-input", {
                                                        attrs: {
                                                          type: "text",
                                                          placeholder:
                                                            _vm.FormMSG(
                                                              28,
                                                              "Type to Search"
                                                            ),
                                                        },
                                                        model: {
                                                          value: _vm.filter,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.filter = $$v
                                                          },
                                                          expression: "filter",
                                                        },
                                                      }),
                                                      _c(
                                                        "b-input-group-append",
                                                        {
                                                          staticClass:
                                                            "cursor-pointer",
                                                        },
                                                        [
                                                          _c(
                                                            "b-input-group-text",
                                                            {
                                                              staticClass:
                                                                "btn-search",
                                                            },
                                                            [
                                                              _vm.filter
                                                                .length === 0
                                                                ? _c(
                                                                    _vm.getLucideIcon(
                                                                      _vm.ICONS
                                                                        .SEARCH
                                                                        .name
                                                                    ),
                                                                    {
                                                                      tag: "component",
                                                                      staticClass:
                                                                        "icon",
                                                                      attrs: {
                                                                        color:
                                                                          _vm
                                                                            .ICONS
                                                                            .SEARCH
                                                                            .color,
                                                                        size: 16,
                                                                        "stroke-width": 2.5,
                                                                      },
                                                                    }
                                                                  )
                                                                : _c(
                                                                    _vm.getLucideIcon(
                                                                      _vm.ICONS
                                                                        .X.name
                                                                    ),
                                                                    {
                                                                      tag: "component",
                                                                      staticClass:
                                                                        "icon",
                                                                      attrs: {
                                                                        color:
                                                                          _vm
                                                                            .ICONS
                                                                            .X
                                                                            .color,
                                                                        size: 16,
                                                                        "stroke-width": 2.5,
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            _vm.filter =
                                                                              ""
                                                                          },
                                                                      },
                                                                    }
                                                                  ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm.notSubmitedExpenses.length > 0
                                            ? _c(
                                                "div",
                                                [
                                                  _c(
                                                    "b-row",
                                                    {
                                                      staticClass:
                                                        "d-flex align-items-center pb-0 pt-3",
                                                      attrs: {
                                                        "align-h": "between",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "b-col",
                                                        {
                                                          staticClass: "pl-4",
                                                          attrs: { md: "6" },
                                                        },
                                                        [
                                                          _c(
                                                            "b-form-group",
                                                            {
                                                              staticClass:
                                                                "mr-3",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "fw-400",
                                                                  staticStyle: {
                                                                    "font-size":
                                                                      "13px",
                                                                    color:
                                                                      "rgba(6, 38, 62, 0.65)",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t*" +
                                                                      _vm._s(
                                                                        _vm.FormMSG(
                                                                          24,
                                                                          "Click on a row to view more informations"
                                                                        )
                                                                      ) +
                                                                      "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  directives: [
                                                                    {
                                                                      name: "show",
                                                                      rawName:
                                                                        "v-show",
                                                                      value:
                                                                        _vm.isActiveSelectAll &&
                                                                        !_vm.isSelectAll,
                                                                      expression:
                                                                        "isActiveSelectAll && !isSelectAll",
                                                                    },
                                                                  ],
                                                                },
                                                                [
                                                                  _c(
                                                                    "b-form-checkbox",
                                                                    {
                                                                      staticClass:
                                                                        "pj-cb",
                                                                      attrs: {
                                                                        size: "lg",
                                                                        id: "select-all-item",
                                                                        value: true,
                                                                        "unchecked-value": false,
                                                                      },
                                                                      on: {
                                                                        change:
                                                                          _vm.setIsSelectAll,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm.isSelectAll,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.isSelectAll =
                                                                              $$v
                                                                          },
                                                                        expression:
                                                                          "isSelectAll",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              "margin-top":
                                                                                "1px",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                38,
                                                                                "Select all"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  directives: [
                                                                    {
                                                                      name: "show",
                                                                      rawName:
                                                                        "v-show",
                                                                      value:
                                                                        _vm.isActiveSelectAll &&
                                                                        _vm.isSelectAll,
                                                                      expression:
                                                                        "isActiveSelectAll && isSelectAll",
                                                                    },
                                                                  ],
                                                                },
                                                                [
                                                                  _c(
                                                                    "b-form-checkbox",
                                                                    {
                                                                      staticClass:
                                                                        "pj-cb",
                                                                      attrs: {
                                                                        value: true,
                                                                        "unchecked-value": false,
                                                                        size: "lg",
                                                                        id: "select-all-item-none",
                                                                      },
                                                                      on: {
                                                                        change:
                                                                          _vm.setIsSelectAll,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm.isSelectAll,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.isSelectAll =
                                                                              $$v
                                                                          },
                                                                        expression:
                                                                          "isSelectAll",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              "margin-top":
                                                                                "1px",
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              _vm.FormMSG(
                                                                                39,
                                                                                "Unselect All"
                                                                              )
                                                                            )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "b-col",
                                                        {
                                                          staticClass:
                                                            "text-right",
                                                          attrs: { md: "6" },
                                                        },
                                                        [
                                                          _c(
                                                            "b-button",
                                                            {
                                                              attrs: {
                                                                variant:
                                                                  "primary",
                                                                disabled:
                                                                  _vm
                                                                    .itemsSelected
                                                                    .length !==
                                                                  0
                                                                    ? false
                                                                    : true,
                                                              },
                                                              on: {
                                                                click:
                                                                  _vm.handSendAllExpensesValidation,
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                  _vm._s(
                                                                    !_vm.isFilmSingle
                                                                      ? _vm.FormMSG(
                                                                          25,
                                                                          "Send expenses for validation"
                                                                        )
                                                                      : _vm.FormMSG(
                                                                          26,
                                                                          "Create expense sheet"
                                                                        )
                                                                  ) +
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c("b-table", {
                                                    staticStyle: {
                                                      "text-align": "left",
                                                    },
                                                    attrs: {
                                                      hover: _vm.hover,
                                                      responsive: "sm",
                                                      items:
                                                        _vm.notSubmitedExpenses,
                                                      filter: _vm.filter,
                                                      fields:
                                                        _vm.notSubmittedTableFields,
                                                      "current-page":
                                                        _vm.notSubmitedYetTableCurrentPage,
                                                      "per-page": _vm.perPage,
                                                      "sticky-header": "700px",
                                                      bordered: "",
                                                      small: "",
                                                      "head-variant": _vm.hv,
                                                    },
                                                    on: {
                                                      "row-clicked":
                                                        _vm.openEditExpense,
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "cell(validated)",
                                                          fn: function (data) {
                                                            return [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "wrap-status",
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      class: `status ${_vm.classStatus(
                                                                        data
                                                                          .item
                                                                          .validated
                                                                      )}`,
                                                                      staticStyle:
                                                                        {
                                                                          "font-size":
                                                                            "0.7rem",
                                                                        },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                          _vm._s(
                                                                            _vm.getLabelStatus(
                                                                              data
                                                                                .item
                                                                                .validated
                                                                            )
                                                                          ) +
                                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                        {
                                                          key: "cell(select)",
                                                          fn: function (data) {
                                                            return [
                                                              _c(
                                                                "span",
                                                                {
                                                                  directives: [
                                                                    {
                                                                      name: "show",
                                                                      rawName:
                                                                        "v-show",
                                                                      value:
                                                                        data
                                                                          .item
                                                                          .validated !==
                                                                          16 &&
                                                                        data
                                                                          .item
                                                                          .validated !==
                                                                          4,
                                                                      expression:
                                                                        "data.item.validated !== 16 && data.item.validated !== 4",
                                                                    },
                                                                  ],
                                                                },
                                                                [
                                                                  +data.item
                                                                    .amountTotal >
                                                                  0
                                                                    ? _c(
                                                                        "b-form-checkbox",
                                                                        {
                                                                          staticClass:
                                                                            "pj-cb pb-1",
                                                                          attrs:
                                                                            {
                                                                              id: `expense-efc-selected-${
                                                                                data
                                                                                  .item
                                                                                  .id +
                                                                                Math.random()
                                                                              }`,
                                                                              name: `expEfcSelected${data.item.id}`,
                                                                              size: "lg",
                                                                            },
                                                                          on: {
                                                                            change:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.handleChangeExpEfcSelected(
                                                                                  $event,
                                                                                  data.item
                                                                                )
                                                                              },
                                                                          },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                data
                                                                                  .item
                                                                                  .isSelected,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    data.item,
                                                                                    "isSelected",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "data.item.isSelected",
                                                                            },
                                                                        }
                                                                      )
                                                                    : _vm._e(),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                        {
                                                          key: "cell(images)",
                                                          fn: function (data) {
                                                            return [
                                                              data.item.images
                                                                .length
                                                                ? _c(
                                                                    "b-button",
                                                                    {
                                                                      attrs: {
                                                                        variant:
                                                                          "success",
                                                                        size: "sm",
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.showPicture(
                                                                              data
                                                                                .item
                                                                                .images
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _c("i", {
                                                                        staticClass:
                                                                          "icon-picture",
                                                                      }),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                            ]
                                                          },
                                                        },
                                                        {
                                                          key: "cell(rem)",
                                                          fn: function (data) {
                                                            return [
                                                              _c(
                                                                _vm.getLucideIcon(
                                                                  _vm.ICONS
                                                                    .TRASH.name
                                                                ),
                                                                {
                                                                  tag: "component",
                                                                  attrs: {
                                                                    color:
                                                                      _vm.ICONS
                                                                        .TRASH
                                                                        .color,
                                                                    size: 20,
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        $event.preventDefault()
                                                                        $event.stopPropagation()
                                                                        return _vm.handleDeleteExpense(
                                                                          data.item
                                                                        )
                                                                      },
                                                                  },
                                                                }
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      793395039
                                                    ),
                                                  }),
                                                  _c(
                                                    "b-row",
                                                    [
                                                      _c(
                                                        "b-col",
                                                        {
                                                          attrs: { cols: "3" },
                                                        },
                                                        [
                                                          _c(
                                                            "b-button",
                                                            {
                                                              attrs: {
                                                                variant:
                                                                  "outline-danger",
                                                                disabled:
                                                                  _vm
                                                                    .itemsSelected
                                                                    .length ===
                                                                  0,
                                                              },
                                                              on: {
                                                                click:
                                                                  _vm.handleClickDeleteSelected,
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                  _vm._s(
                                                                    _vm.FormMSG(
                                                                      458,
                                                                      "Delete selected"
                                                                    )
                                                                  ) +
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.notSubmitedExpenses.length < 1
                                            ? _c(
                                                "div",
                                                { staticClass: "empty-state" },
                                                [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t" +
                                                      _vm._s(
                                                        _vm.FormMSG(
                                                          27,
                                                          "You have no expense items yet"
                                                        )
                                                      ) +
                                                      "\n\t\t\t\t\t\t\t\t\t"
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-tab",
                                    { attrs: { title: _vm.tabTwoCaption() } },
                                    [
                                      _c(
                                        "b-card-text",
                                        [
                                          _c(
                                            "b-row",
                                            [
                                              _c(
                                                "b-col",
                                                {
                                                  staticClass: "pb-3",
                                                  attrs: { cols: "6" },
                                                },
                                                [
                                                  _c(
                                                    "b-input-group",
                                                    [
                                                      _c("b-form-input", {
                                                        attrs: {
                                                          type: "text",
                                                          placeholder:
                                                            _vm.FormMSG(
                                                              28,
                                                              "Type to Search"
                                                            ),
                                                        },
                                                        model: {
                                                          value: _vm.filter,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.filter = $$v
                                                          },
                                                          expression: "filter",
                                                        },
                                                      }),
                                                      _c(
                                                        "b-input-group-append",
                                                        {
                                                          staticClass:
                                                            "cursor-pointer",
                                                        },
                                                        [
                                                          _c(
                                                            "b-input-group-text",
                                                            {
                                                              staticClass:
                                                                "btn-search",
                                                            },
                                                            [
                                                              _vm.filter
                                                                .length === 0
                                                                ? _c(
                                                                    _vm.getLucideIcon(
                                                                      _vm.ICONS
                                                                        .SEARCH
                                                                        .name
                                                                    ),
                                                                    {
                                                                      tag: "component",
                                                                      staticClass:
                                                                        "icon",
                                                                      attrs: {
                                                                        color:
                                                                          _vm
                                                                            .ICONS
                                                                            .SEARCH
                                                                            .color,
                                                                        size: 16,
                                                                        "stroke-width": 2.5,
                                                                      },
                                                                    }
                                                                  )
                                                                : _c(
                                                                    _vm.getLucideIcon(
                                                                      _vm.ICONS
                                                                        .X.name
                                                                    ),
                                                                    {
                                                                      tag: "component",
                                                                      staticClass:
                                                                        "icon",
                                                                      attrs: {
                                                                        color:
                                                                          _vm
                                                                            .ICONS
                                                                            .X
                                                                            .color,
                                                                        size: 16,
                                                                        "stroke-width": 2.5,
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            _vm.filter =
                                                                              ""
                                                                          },
                                                                      },
                                                                    }
                                                                  ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c("b-table", {
                                            attrs: {
                                              hover: _vm.hover,
                                              responsive: "sm",
                                              filter: _vm.filter,
                                              items: _vm.submitedExpenses,
                                              fields: _vm.submittedTableFields,
                                              "current-page": _vm.currentPage,
                                              "per-page": 0,
                                              bordered: "",
                                              small: "",
                                              "sticky-header": "700px",
                                              "head-variant": _vm.hv,
                                            },
                                            on: {
                                              "row-clicked": _vm.rowClicked,
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "cell(validated)",
                                                  fn: function (data) {
                                                    return [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "wrap-status",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              class: `status ${_vm.classStatus(
                                                                data.item
                                                                  .validated
                                                              )}`,
                                                              staticStyle: {
                                                                "font-size":
                                                                  "0.7rem",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                  _vm._s(
                                                                    _vm.getLabelStatus(
                                                                      data.item
                                                                        .validated
                                                                    )
                                                                  ) +
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              2574497073
                                            ),
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.notSubmitedExpenses.length > 0 && _vm.currentExpenseTab === 0
                ? _c(
                    "b-button",
                    {
                      staticClass:
                        "hide-on-desktop send-forvaldiation fix-float-mobile-btn is-shadow",
                      attrs: { variant: "primary" },
                      on: { click: _vm.handSendAllExpensesValidation },
                    },
                    [
                      !_vm.isFilmSingle
                        ? _c("div", [
                            _c("span", [
                              _vm._v(_vm._s(_vm.FormMSG(29, "Send expenses"))),
                            ]),
                            _c("span", [
                              _vm._v(_vm._s(_vm.FormMSG(30, "for validation"))),
                            ]),
                          ])
                        : _c("div", [
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.FormMSG(31, "Create expense sheet"))
                              ),
                            ]),
                          ]),
                    ]
                  )
                : _vm._e(),
              _c("ExpenseService", {
                key: _vm.expenseModalRefreshToken,
                attrs: {
                  useComponent: true,
                  "expense-type": _vm.newExpenseType,
                  "is-new": _vm.isNew,
                  "edit-data": _vm.currEditExpense,
                },
                on: {
                  "payload-refreshed": _vm.handleCurrExpenseChanged,
                  "expense-service-main:error": _vm.getError,
                  submited: _vm.handleModalSubmited,
                  "expense-form-component:close": _vm.onCloseExpenseForm,
                },
                model: {
                  value: _vm.isNewExpenseModalOpen,
                  callback: function ($$v) {
                    _vm.isNewExpenseModalOpen = $$v
                  },
                  expression: "isNewExpenseModalOpen",
                },
              }),
              _c("title-expense-modal", {
                attrs: { "reinitialize-value": _vm.reinitializeTitle },
                on: {
                  "title-expense-modal:cancel": _vm.onCancelTitleModalPC,
                  "title-expense-modal:add": _vm.onAddTitleModalPC,
                },
                model: {
                  value: _vm.isOpenTitleModalPC,
                  callback: function ($$v) {
                    _vm.isOpenTitleModalPC = $$v
                  },
                  expression: "isOpenTitleModalPC",
                },
              }),
              _c("KlippaOneTicketFromPicture", {
                key: _vm.klippaModalRefreshKey,
                attrs: {
                  "has-user-selection": "",
                  "has-auto-open-capture-modal": false,
                  "multiple-doc": _vm.isKlippaOcrMultipleDocument,
                  "split-tva": _vm.isTvaSplitting,
                },
                on: {
                  submited: _vm.handleModalSubmited,
                  closed: _vm.handleKlippaModalRefreshKey,
                },
                model: {
                  value: _vm.isKlippaOcrModalOpen,
                  callback: function ($$v) {
                    _vm.isKlippaOcrModalOpen = $$v
                  },
                  expression: "isKlippaOcrModalOpen",
                },
              }),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }