<template>
	<errorContainer :error="erreur" :warning="warning">
		<custom-loading-planning :loading="isLoading" :label="FormMSG(37, 'Prepare data of expense entry for crew')" />
		<div v-if="$screen.width <= 576" class="animated fadeIn">
			<div class="container-mobile">
				<div :class="`my-expense-mobile ${$screen.width > 576 ? 'spacing-pad' : ''}`">
					<div class="title">
						<h1 class="main-page-title" :class="{ 'is-pwa': $isPwa() }">
							{{ FormMSG(90, 'Expenses for crew') }}
						</h1>
					</div>
					<div class="d-flex flex-column position">
						<div class="my-expense-actions border-style" :class="{ 'pt-16': $isPwa() }">
							<div class="d-flex justify-content-start title-add">{{ FormMSG(2, 'Add Expense') }}</div>
							<div class="d-flex justify-content-center wapper">
								<router-link to="/myexpenses/1/n/2?efc=1" class="btn-action gap-right" tag="div">
									<img src="svgs/mileage.svg" alt="Mileage" />
									<span>{{ FormMSG(3, 'Mileage') }}</span>
								</router-link>
								<router-link to="/myexpenses/2/n/0?efc=2" class="btn-action gap-right" tag="div">
									<img src="svgs/expense.svg" alt="Ticket" />
									<span>{{ FormMSG(4, 'Ticket') }}</span>
								</router-link>
								<router-link to="/myexpenses/3/n/1?efc=3" class="btn-action" tag="div" v-if="!isFilmSingle">
									<img src="svgs/invoice.svg" alt="Invoice" />
									<span>{{ FormMSG(5, 'Invoice') }}</span>
								</router-link>
							</div>
						</div>
					</div>
					<div>
						<div class="my-expense-status-actions">
							<div class="status-actions">
								<b-button-group>
									<b-button
										:pressed="notSubmittedState"
										:class="`not-submitted${notSubmittedState ? '' : ' not-active'}`"
										aria-pressed="true"
										@click="setStatusExpenseDefault(0)"
										>{{ firstTabCaptionMobile }}</b-button
									>
									<b-button
										:pressed="submittedState"
										:class="`submitted${submittedState ? '' : ' not-active'}`"
										aria-pressed="true"
										@click="setStatusExpenseDefault(1)"
										>{{ secondTabCaptionMobile }}</b-button
									>
								</b-button-group>
							</div>
							<div class="check-actions cursor-pointer" v-if="notSubmittedState && notSubmittedCopyIds.length > 0" @click="switchCheckAll">
								<div class="d-flex justify-content-end align-items-center" v-if="checkAll">
									<span class="pr-2">{{ FormMSG(6, 'All') }}</span
									><check-square :size="16" />
								</div>
								<div class="d-flex justify-content-end align-items-center" v-else>
									<span class="pr-2">{{ FormMSG(7, 'None') }}</span
									><square :size="16" />
								</div>
							</div>
						</div>
						<div class="content pb-3" v-if="GetExpenseMobile.length > 0">
							<div
								class="card-expense"
								v-for="(expense, index) in GetExpenseMobile"
								:key="expense.id + index"
								:id="`${notSubmittedState && parseInt(expense.validated, 10) === 0 ? generateTextId('card-expense-' + expense.id) : ''}`"
							>
								<div class="wrapper">
									<div class="resume">
										<div class="first-col" v-if="notSubmittedState && parseInt(expense.validated, 10) === 0">
											<b-form-checkbox
												size="lg"
												v-model="notSubmittedIds"
												:value="expense.id"
												@change="setSelectedItem($event, expense, generateTextId('card-expense-' + expense.id))"
											/>
										</div>
										<div
											class="second-col"
											:style="`width: ${notSubmittedState && parseInt(expense.validated, 10) === 0 ? '56.5%;' : '67.5%;'}`"
										>
											<div class="description" v-if="showExpenseTitle(expense)">
												{{ `${expense.user.name + ' ' + expense.user.firstName}` }} - {{ expense.description }}
											</div>
											<div class="title">#{{ expense.id }}</div>
											<div class="amount">
												{{ rendAmountTotal(expense.amountTotal) }}
											</div>
											<div class="date">
												{{ dateToLiteral(expense.date) }}
											</div>
											<div class="carbon" v-if="expense.type !== 2">
												<div class="icon">
													<component :is="getLucideIcon(ICONS.SPROUT.name)" :color="ICONS.SPROUT.color" :size="14" />
												</div>
												<div class="label">
													{{ rendCo2(expense.kgCoTwo) }}<span> CO<sub>2</sub></span>
												</div>
											</div>
											<div class="mileage-resume" v-else>
												<div class="details pt-1" v-if="expense.fromLocation && expense.toLocation">
													<div class="first-col">
														<div class="icon-rotate">
															<component
																:is="getLucideIcon(ICONS.ARROWLEFTRIGHT.name)"
																:color="ICONS.ARROWLEFTRIGHT.color"
																:size="16"
															/>
														</div>
													</div>
													<div class="second-col">
														<div>{{ expense.fromLocation }}</div>
														<div>{{ expense.toLocation }}</div>
													</div>
												</div>
												<div class="details" :class="`${!expense.km ? '' : 'pt-2'}`">
													<div class="first-col">
														<div class="icon">
															<component
																:is="getLucideIcon(ICONS.SPROUT.name)"
																:color="ICONS.SPROUT.color"
																:size="!expense.km ? 14 : 16"
															/>
														</div>
													</div>
													<div class="second-col" :class="`${!expense.km ? 'spl-4' : ''}`">
														<div v-if="expense.km">{{ recalculateDistanceByUnit(expense.km) }} {{ distanceUnit }}</div>
														<div :style="`margin-bottom: ${expense.km ? '0' : '-1px'}`">
															{{ rendCo2(expense.kgCoTwo) }} CO<sub>2</sub>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div class="third-col">
											<div class="status-wrapper">
												<div :class="`status ${classStatus(expense.validated)}`" v-if="!isFilmSingle">
													{{ getLabelStatus(expense.validated) }}
												</div>
												<div :class="`${!isFilmSingle ? 'type mt-2' : 'type'}`">
													<span class="icon">
														<component
															:is="getLucideIcon(ICONS.TICKET.name)"
															:color="ICONS.TICKET.color"
															:size="16"
															v-if="expense.type === 0"
														/>
														<component
															:is="getLucideIcon(ICONS.FILE_TEXT.name)"
															:color="ICONS.FILE_TEXT.color"
															:size="14"
															v-if="expense.type === 1"
														/>
														<component
															:is="getLucideIcon(ICONS.CAR.name)"
															:color="ICONS.CAR.color"
															:size="16"
															v-if="expense.type === 2"
														/>
													</span>
													<span class="label" :style="`${expense.type === 2 ? 'margin-top: 2px;' : ''}`">{{
														setType(expense.type)
													}}</span>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="footer">
									<div class="actions">
										<div class="first-col" v-if="notSubmittedState && expense.images.length > 0" @click="showPicture(expense.images)">
											<div class="icon">
												<component :is="getLucideIcon(ICONS.PHOTO_MOBILE.name)" :color="ICONS.PHOTO_MOBILE.color" :size="16" />
											</div>
											<div class="label">{{ FormMSG(8, 'See Receipt') }}</div>
										</div>
										<div
											class="second-col"
											:style="`width: ${(notSubmittedState && expense.images.length === 0) || submittedState ? '100%;' : '60%;'}`"
										>
											<div class="cursor-pointer d-flex flex-row pr-3" v-if="notSubmittedState" @click="editExpenseMobile(expense)">
												<div class="icon">
													<component :is="getLucideIcon(ICONS.EDIT_MOBILE.name)" :color="ICONS.EDIT_MOBILE.color" :size="16" />
												</div>
												<div class="label">{{ FormMSG(9, 'Edit') }}</div>
											</div>
											<div class="cursor-pointer d-flex flex-row" v-if="submittedState" @click="viewExpenseDatailMobile(expense, isHome)">
												<div class="icon">
													<component :is="getLucideIcon(ICONS.EYE.name)" :color="ICONS.EYE.color" :size="16" />
												</div>
												<div class="label">{{ FormMSG(10, 'View') }}</div>
											</div>
											<div class="cursor-pointer d-flex flex-row" v-if="notSubmittedState" @click="deleteExpenseMobile(expense.id)">
												<div class="icon">
													<component :is="getLucideIcon(ICONS.TRASH_MOBILE.name)" :color="ICONS.TRASH_MOBILE.color" :size="16" />
												</div>
												<div class="label">{{ FormMSG(11, 'Delete') }}</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div v-else class="empty-data">
							{{ FormMSG(12, 'No expense found') }}
						</div>
					</div>
					<b-row class="footer-fixed" v-if="notSubmittedState && notSubmittedIds.length > 0">
						<b-col cols="12">
							<b-button block size="lg" variant="primary" class="mb-2" @click="handleSendExpensesForValidationMobile">
								<span v-if="!isFilmSingle">{{ FormMSG(13, 'Send Expenses for Validation') }}</span>
								<span v-else>{{ FormMSG(14, 'Create expenses sheet') }}</span>
							</b-button>
						</b-col>
					</b-row>
				</div>
			</div>
			<title-expense-modal
				v-model="isOpenTitleModal"
				:reinitialize-value="reinitializeTitle"
				@title-expense-modal:cancel="onCancelTitleModal"
				@title-expense-modal:add="onAddTitleModal"
			/>
		</div>
		<div class="form" v-else>
			<b-row align-h="between">
				<b-col lg="4">
					<h1>{{ FormMSG(90, 'Expenses for crew') }}</h1>
				</b-col>
				<b-col lg="8" class="text-right">
					<b-button-group>
						<b-dropdown right :text="FormMSG(16, 'Create...')" variant="success mr-2">
							<b-dropdown-item @click="openExpenseModal(2, true)"> {{ FormMSG(17, 'Mileage') }}</b-dropdown-item>
							<b-dropdown-item @click="openExpenseModal(0, true)"> {{ FormMSG(18, 'Ticket') }}</b-dropdown-item>
							<b-dropdown-item @click="handleCreateKlippaOcrOpenModal({ isMultipe: false, isSplitTva: true })" v-if="project.useKlippaOcr">
								{{ FormMSG(40, 'Create one ticket from pictures') }}
							</b-dropdown-item>
							<b-dropdown-item @click="handleCreateKlippaOcrOpenModal({ isMultipe: true })" v-if="project.useKlippaOcr">
								{{ FormMSG(41, 'Create multiple ticket from pictures (one picture/ticket)') }}
							</b-dropdown-item>
							<b-dropdown-item v-if="!isFilmSingle" @click="openExpenseModal(1, true)"> {{ FormMSG(19, 'Invoice') }}</b-dropdown-item>
						</b-dropdown>
					</b-button-group>
				</b-col>
			</b-row>
			<b-row v-if="notSubmitedExpenses && submitedExpenses">
				<b-col cols="12" xl="12">
					<b-card no-body>
						<b-tabs v-model="currentExpenseTab" card>
							<b-tab :title="tabOneCaption()">
								<b-card-text>
									<b-row>
										<b-col cols="6" class="pb-3">
											<b-input-group>
												<b-form-input v-model="filter" type="text" :placeholder="FormMSG(28, 'Type to Search')" />
												<b-input-group-append class="cursor-pointer">
													<b-input-group-text class="btn-search">
														<component
															:is="getLucideIcon(ICONS.SEARCH.name)"
															:color="ICONS.SEARCH.color"
															:size="16"
															class="icon"
															:stroke-width="2.5"
															v-if="filter.length === 0"
														/>
														<component
															:is="getLucideIcon(ICONS.X.name)"
															:color="ICONS.X.color"
															:size="16"
															class="icon"
															:stroke-width="2.5"
															@click="filter = ''"
															v-else
														/>
													</b-input-group-text>
												</b-input-group-append>
											</b-input-group>
										</b-col>
									</b-row>
									<div v-if="notSubmitedExpenses.length > 0">
										<b-row class="d-flex align-items-center pb-0 pt-3" align-h="between">
											<b-col md="6" class="pl-4">
												<b-form-group class="mr-3">
													<div class="fw-400" style="font-size: 13px; color: rgba(6, 38, 62, 0.65)">
														*{{ FormMSG(24, 'Click on a row to view more informations') }}
													</div>
													<div v-show="isActiveSelectAll && !isSelectAll">
														<b-form-checkbox
															size="lg"
															id="select-all-item"
															v-model="isSelectAll"
															:value="true"
															:unchecked-value="false"
															@change="setIsSelectAll"
															class="pj-cb"
														>
															<div style="margin-top: 1px">{{ FormMSG(38, 'Select all') }}</div>
														</b-form-checkbox>
													</div>
													<div v-show="isActiveSelectAll && isSelectAll">
														<b-form-checkbox
															v-model="isSelectAll"
															:value="true"
															:unchecked-value="false"
															size="lg"
															id="select-all-item-none"
															class="pj-cb"
															@change="setIsSelectAll"
														>
															<div style="margin-top: 1px">{{ FormMSG(39, 'Unselect All') }}</div>
														</b-form-checkbox>
													</div>
												</b-form-group>
											</b-col>
											<b-col md="6" class="text-right">
												<b-button
													variant="primary"
													@click="handSendAllExpensesValidation"
													:disabled="itemsSelected.length !== 0 ? false : true"
												>
													{{ !isFilmSingle ? FormMSG(25, 'Send expenses for validation') : FormMSG(26, 'Create expense sheet') }}
												</b-button>
											</b-col>
										</b-row>
										<b-table
											:hover="hover"
											responsive="sm"
											:items="notSubmitedExpenses"
											:filter="filter"
											style="text-align: left"
											:fields="notSubmittedTableFields"
											:current-page="notSubmitedYetTableCurrentPage"
											:per-page="perPage"
											sticky-header="700px"
											bordered
											@row-clicked="openEditExpense"
											small
											:head-variant="hv"
										>
											<template v-slot:cell(validated)="data">
												<div class="wrap-status">
													<div :class="`status ${classStatus(data.item.validated)}`" style="font-size: 0.7rem">
														{{ getLabelStatus(data.item.validated) }}
													</div>
												</div>
											</template>
											<template #cell(select)="data">
												<span v-show="data.item.validated !== 16 && data.item.validated !== 4">
													<b-form-checkbox
														v-if="+data.item.amountTotal > 0"
														:id="`expense-efc-selected-${data.item.id + Math.random()}`"
														:name="`expEfcSelected${data.item.id}`"
														v-model="data.item.isSelected"
														@change="handleChangeExpEfcSelected($event, data.item)"
														size="lg"
														class="pj-cb pb-1"
													/>
												</span>
											</template>
											<template #cell(images)="data">
												<b-button v-if="data.item.images.length" variant="success" size="sm" @click="showPicture(data.item.images)">
													<i class="icon-picture"></i>
												</b-button>
											</template>
											<template #cell(rem)="data">
												<component
													:is="getLucideIcon(ICONS.TRASH.name)"
													:color="ICONS.TRASH.color"
													:size="20"
													@click.prevent.stop="handleDeleteExpense(data.item)"
												/>
											</template>
										</b-table>

										<b-row>
											<b-col cols="3">
												<b-button variant="outline-danger" :disabled="itemsSelected.length === 0" @click="handleClickDeleteSelected">
													{{ FormMSG(458, 'Delete selected') }}
												</b-button>
											</b-col>
										</b-row>
									</div>
									<div v-if="notSubmitedExpenses.length < 1" class="empty-state">
										{{ FormMSG(27, 'You have no expense items yet') }}
									</div>
								</b-card-text>
							</b-tab>
							<b-tab :title="tabTwoCaption()">
								<b-card-text>
									<b-row>
										<b-col cols="6" class="pb-3">
											<b-input-group>
												<b-form-input v-model="filter" type="text" :placeholder="FormMSG(28, 'Type to Search')" />
												<b-input-group-append class="cursor-pointer">
													<b-input-group-text class="btn-search">
														<component
															:is="getLucideIcon(ICONS.SEARCH.name)"
															:color="ICONS.SEARCH.color"
															:size="16"
															class="icon"
															:stroke-width="2.5"
															v-if="filter.length === 0"
														/>
														<component
															:is="getLucideIcon(ICONS.X.name)"
															:color="ICONS.X.color"
															:size="16"
															class="icon"
															:stroke-width="2.5"
															@click="filter = ''"
															v-else
														/>
													</b-input-group-text>
												</b-input-group-append>
											</b-input-group>
										</b-col>
									</b-row>
									<b-table
										:hover="hover"
										responsive="sm"
										:filter="filter"
										:items="submitedExpenses"
										:fields="submittedTableFields"
										:current-page="currentPage"
										:per-page="0"
										bordered
										@row-clicked="rowClicked"
										small
										sticky-header="700px"
										:head-variant="hv"
									>
										<template v-slot:cell(validated)="data">
											<div class="wrap-status">
												<div :class="`status ${classStatus(data.item.validated)}`" style="font-size: 0.7rem">
													{{ getLabelStatus(data.item.validated) }}
												</div>
											</div>
										</template>
									</b-table>
								</b-card-text>
							</b-tab>
						</b-tabs>
					</b-card>
				</b-col>
			</b-row>
			<b-button
				v-if="notSubmitedExpenses.length > 0 && currentExpenseTab === 0"
				class="hide-on-desktop send-forvaldiation fix-float-mobile-btn is-shadow"
				variant="primary"
				@click="handSendAllExpensesValidation"
			>
				<div v-if="!isFilmSingle">
					<span>{{ FormMSG(29, 'Send expenses') }}</span>
					<span>{{ FormMSG(30, 'for validation') }}</span>
				</div>
				<div v-else>
					<span>{{ FormMSG(31, 'Create expense sheet') }}</span>
				</div>
			</b-button>
			<ExpenseService
				:useComponent="true"
				:key="expenseModalRefreshToken"
				v-model="isNewExpenseModalOpen"
				:expense-type="newExpenseType"
				:is-new="isNew"
				:edit-data="currEditExpense"
        @payload-refreshed="handleCurrExpenseChanged"
				@expense-service-main:error="getError"
				@submited="handleModalSubmited"
				@expense-form-component:close="onCloseExpenseForm"
			/>
			<title-expense-modal
				v-model="isOpenTitleModalPC"
				:reinitialize-value="reinitializeTitle"
				@title-expense-modal:cancel="onCancelTitleModalPC"
				@title-expense-modal:add="onAddTitleModalPC"
			/>
			<KlippaOneTicketFromPicture
        v-model="isKlippaOcrModalOpen"
        has-user-selection
        :key="klippaModalRefreshKey"
        :has-auto-open-capture-modal="false"
        :multiple-doc="isKlippaOcrMultipleDocument"
        :split-tva="isTvaSplitting"
        @submited="handleModalSubmited"
        @closed="handleKlippaModalRefreshKey"
      />
		</div>
	</errorContainer>
</template>

<script>
import moment from 'moment';
import { Image as Photo, Edit, Trash2 as Trash, FileText, Ticket, Car, Sprout, Eye, CheckSquare, Square, ArrowLeftRight } from 'lucide-vue';
import { mapGetters, mapActions } from 'vuex';
import Loading from 'vue-loading-overlay';
import { generateSecureId, isNil, makeID } from '@/shared/utils';
import { rendCurrency, rendKgCo2, formatTableDate } from '~helpers';
import { getFileExtension } from '@/shared/helpers';
import mapProps from '@/shared/vuePropsMapper';
import { recalculateDistanceValueByUnit } from '@/shared/google/helpers';
import KlippaOneTicketFromPicture from '@/components/ExpenseService/KlippaOneTicketFromPicture';
import ExpenseService from '@/components/ExpenseService/Main';
import MyExpensesMobileView from '@/components/MobileViews/MyExpense/Main';
import WalletIconSVG from '@/components/icons/wallet';
import InvoiceIconSVG from '@/components/icons/invoice';
import CarMileageIconSVG from '@/components/icons/car-mileage';
import { sendSelectedExpensesForValidation } from '@/cruds/expense.crud';
import TitleExpenseModal from '@/views/myexpenses/TitleExpenseModal';
import { store } from '@/store';

import globalMixin from '@/mixins/global.mixin';
import languageMessages from '@/mixins/languageMessages';
import isSingleProjectMixin from '@/mixins/isSingleProject.mixin';
import { getExpenseItemsNotSubmitted, getExpenseItemsSubmitted, deleteExpenseEfc, UpdatedExpenseItemEfc } from '@/cruds/entryForCrew.crud';
import { classStatus as classStatusName } from '@/shared/global-status';
import CustomLoadingPlanning from '@/components/Plannings/Filter/CustomLoadingPlanning';
import _ from 'lodash';

export default {
	name: 'ExpensesEntryCrew',
	components: {
		Loading,
		ExpenseService,
		WalletIconSVG,
		InvoiceIconSVG,
		CarMileageIconSVG,
		MyExpensesMobileView,
		Photo,
		Edit,
		Trash,
		FileText,
		Ticket,
		Car,
		Sprout,
		Eye,
		CheckSquare,
		Square,
		ArrowLeftRight,
		TitleExpenseModal,
		CustomLoadingPlanning,
		KlippaOneTicketFromPicture
	},
	mixins: [languageMessages, isSingleProjectMixin, globalMixin],
	props: {
		...mapProps(['hover', 'striped', 'bordered'], {
			type: Boolean,
			default: true
		}),
		...mapProps(['small', 'fixed'], {
			type: Boolean,
			default: false
		}),
		caption1: {
			type: String,
			default: 'My Expenses'
		}
	},
	data() {
		let classStatus = (item) => classStatusName(item);
		let isHome = window.location.href.split`#/`[1];
		return {
			hv: 'dark',
			Validated: 0,
			erreur: {},
			isHome,
			notSubmitedYetTableCurrentPage: 1,
			currentPage: 1,
			perPage: 0,
			warning: '',
			successModal: false,
			isLoading: false,
			filter: '',
			project: {},
			isCaptureModalOpen: false,
			classStatus,
			curExp: {
				id: 0,
				date: '',
				type: 0,
				amountTotal: 0,
				validated: 0,
				validatedStatus: 'Not Submited',
				typeString: ''
			},
			currEditExpense: null,
			// CREATE NEW EXPENSES MODAL CONTAINER
			isNewExpenseModalOpen: false,
			newExpenseType: 2, // default km mileage
			expenseModalRefreshToken: 0,
			isSubmitted: false,
			notSubmittedState: true,
			submittedState: false,
			expenses: [],
			notSubmittedIds: [],
			notSubmittedCopyIds: [],
			checkAll: true,
			itemsSelected: [],
			isOpenTitleModal: false,
			isOpenTitleModalPC: false,
			reinitializeTitle: false,
			notSubmitedExpenses: [],
			submitedExpenses: [],
			isNew: false,
			expenseStatusDefault: 0,
			isSelectAll: false,
			isNone: false,
			isActiveSelectAll: false,
			isKlippaOcrModalOpen: false,
			isKlippaOcrMultipleDocument: false,
      isTvaSplitting: false,
      klippaModalRefreshKey: makeID(10),
		};
	},
	computed: {
		GetExpenseMobile() {
			let result = this.getExpenseList(this.expenseStatusDefault);
			if (result.length !== 0) {
				return result;
			}
			return [];
		},
		currentExpenseTab: {
			get() {
				if (this.$route.query.activeSecondTab) {
					return 1;
				}
				return this.expensesActiveTab;
			},
			set(tab) {
				this.handleTabChanged(tab);
			}
		},
		submittedTableFields() {
			if (this.isFilmSingle) {
				const keys = ['validatedStatus'];
				return this.submittedTableFieldsBase.filter((t) => !keys.includes(t.key));
			} else {
				return this.submittedTableFieldsBase;
			}
		},
		submittedTableFieldsBase() {
			const _t = this.FormMSG;
			return [
				{
					key: 'id',
					label: _t(78781, 'Id'),
					formatter: (v) => `${v}`,
					sortable: true
				},
				{
					key: 'validated',
					label: _t(78789, 'Status'),
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'crew_member',
					label: _t(32, 'Crew member'),
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'type',
					label: _t(78782, 'Type'),
					formatter: (v) => this.setType(v),
					sortable: true
				},
				{
					key: 'description',
					label: _t(78784, 'Description'),
					formatter: (v) => v,
					sortable: true
				},
				{
					key: 'date',
					label: _t(4451, 'Date'),
					formatter: (v) => formatTableDate(v),
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'kgCoTwo',
					label: 'CO2',
					formatter: (v) => rendKgCo2(v),
					sortable: true,
					class: 'text-right'
				},
				{
					key: 'amountTotal',
					label: _t(4452, 'Amount'),
					formatter: (v) => rendCurrency(v),
					sortable: true,
					class: 'text-right'
				}
			];
		},
		notSubmittedTableFields() {
			if (this.isFilmSingle) {
				const keys = ['validatedStatus'];
				return this.notSubmittedTableFieldsBase.filter((t) => !keys.includes(t.key));
			} else {
				return this.notSubmittedTableFieldsBase;
			}
		},
		notSubmittedTableFieldsBase() {
			const _t = this.FormMSG;
			return [
				{
					key: 'select',
					label: _t(31, 'Select'),
					class: 'text-center'
				},
				{
					key: 'id',
					label: _t(78781, 'Id'),
					formatter: (v) => `${v}`,
					sortable: true
				},
				{
					key: 'validated',
					label: _t(78789, 'Status'),
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'crew_member',
					label: _t(32, 'Crew member'),
					sortable: true,
					class: 'text-center'
				},
				{
					key: 'type',
					label: _t(78782, 'Type'),
					formatter: (v) => this.setType(v),
					sortable: true
				},
				{
					key: 'description',
					label: _t(78784, 'Description'),
					formatter: (v) => v,
					sortable: true
				},
				{
					key: 'date',
					label: _t(4462, 'Date'),
					formatter: (v) => formatTableDate(v),
					sortable: true
				},
				{
					key: 'departmentName',
					label: _t(4463, 'Department'),
					formatter: (v) => v,
					sortable: true
				},
				{
					key: 'kgCoTwo',
					label: 'CO2',
					formatter: (v) => rendKgCo2(v),
					sortable: true,
					class: 'text-right'
				},
				{
					key: 'amountTotal',
					label: _t(4465, 'Amount'),
					formatter: (v) => rendCurrency(v),
					sortable: true,
					class: 'text-right'
				},
				{
					key: 'amountVat',
					label: _t(230, 'VAT'),
					formatter: (v) => rendCurrency(v),
					sortable: true,
					class: 'text-right'
				},
				{
					key: 'images',
					label: _t(4466, 'Picture'),
					formatter: (v) => v,
					sortable: false,
					class: 'text-center'
				},
				{
					key: 'rem',
					label: _t(4467, 'Delete'),
					sortable: false,
					formatter: (v) => v,
					class: 'text-center'
				}
			];
		},
		rendConfirmModalTxt() {
			const _t = this.FormMSG;
			return {
				title: _t(56561, 'Please Confirm'),
				okTitle: _t(56562, 'YES'),
				cancelTitle: _t(56563, 'NO')
			};
		},
		captionCheckAll() {
			return this.checkAll ? this.FormMSG(17, 'Select All') : this.FormMSG(35, 'Unselect All');
		},
		firstTabCaptionMobile() {
			if (!this.isFilmSingle) {
				return this.FormMSG(34, 'Not Submitted');
			} else {
				return this.FormMSG(35, 'Not Submitted');
			}
		},
		secondTabCaptionMobile() {
			if (!this.isFilmSingle) {
				return this.FormMSG(36, 'Submitted');
			} else {
				return this.FormMSG(37, 'Submitted');
			}
		},
		distanceUnit() {
			return store.state.myProfile.distanceUnit;
		}
	},
	watch: {
		isActiveSelectAll: {
			handler(newVal) {
				return newVal;
			},
			immediate: true,
			deep: true
		},
		notSubmitedExpenses: {
			handler(newArrays) {
				this.isActiveSelectAll = newArrays.some((item) => +item.amountTotal !== 0 && +item.validated !== 4 && +item.validated !== 16);
				return newArrays;
			},
			immediate: true,
			deep: true
		}
	},
	created() {
		this.$nextTick(async () => {
			this.project = store.state.myProject;
			const home = window.location.href.split`#/`[1];
			await this.initData();
			await this.getExpenseList(this.expenseStatusDefault);
			const { form, action, type, back, s, ec, eu } = this.$route.query;
			if (!isNil(action) && !isNil(type)) {
				let path = `${home}/${form}/${action}/${type}`;
				if (!isNil(back)) {
					path += `?back=${back}`;
				}
				this.$router.push({ path });
			}

			if (!isNil(s) && s === '0') {
				this.createToastForMobile(this.FormMSG(43, 'Success'), this.FormMSG(44, 'Expense updated successfully'), '');

				this.$router.push({ home });
			}

			if (!isNil(ec)) {
				let message = '';
				if (ec === '509') {
					if (!isNil(eu) && eu === '1') {
						message = this.FormMSG(
							129,
							'Sorry, cannot perfrom this action because your contract will be expired at this date, please contact your administrator.'
						);
					} else {
						message = this.FormMSG(
							127,
							'Sorry, cannot perfrom this action because your contract has been expired, please contact your administrator.'
						);
					}
				} else {
					message = this.FormMSG(128, 'An error was occured during create an expense, please contact your administrator.');
				}

				this.createToastForMobile(this.FormMSG(125, 'Error'), message, '', 'danger');

				this.$router.push({ home });
			}
		});
	},
	mounted() {
		if (this.$route.query.activeSecondTab) {
			this.notSubmittedState = false;
			this.notSubmittedState = true;
		}
	},
	methods: {
		...mapActions({
			sendToValidation: 'expenses/sendToValidation',
			setFocusExpenseItem: 'expenses/setFocusExpenseItem',
			getCurExpense: 'expenses/getCurExpense',
			handleTabChanged: 'expenses/setActiveTab',
			setCurrentExpenseMobile: 'expenses/setCurrentExpenseMobile'
		}),

		async handleClickDeleteSelected() {
			const action = async () => {
				const actionForLoader = async () => {
					for (let i = 0; i < this.itemsSelected.length; i++) {
						await this.handleDeleteExpense(this.itemsSelected[i], false);
					}

					this.itemsSelected = [];

					this.createToastForMobile(this.FormMSG(43, 'Success'), this.FormMSG(857, 'Expenses selected removed successfully!'));
				};

				await this.showLoader(actionForLoader);
			};

			this.confirmModal(action, this.FormMSG(814, 'Are you sure ?'));
		},

		/**
     * @param {Object<{Boolean,Boolean}>} { isMultipe, isSplitTva }
		 */
    handleCreateKlippaOcrOpenModal({ isMultipe, isSplitTva }) {
			this.isKlippaOcrMultipleDocument = isMultipe || false;
      this.isTvaSplitting = isSplitTva || false;
			this.isKlippaOcrModalOpen = true;
		},
		setIsSelectAll() {
			!this.isSelectAll;
			if (this.isSelectAll) {
				this.notSubmitedExpenses = this.notSubmitedExpenses.map((item) => {
					if (item.validated === 4 || item.validated === 16) {
						return { ...item, isSelected: false };
					} else {
						return { ...item, isSelected: this.isSelectAll };
					}
				});
				this.itemsSelected = this.notSubmitedExpenses.filter((item) => +item.amountTotal !== 0 && +item.validated !== 4 && item.validated !== 16);
			} else {
				this.notSubmitedExpenses = this.notSubmitedExpenses.map((item) => {
					return { ...item, isSelected: this.isSelectAll };
				});
				this.itemsSelected = [];
			}
		},
		handleChangeExpEfcSelected($event, data) {
			if ($event) {
				this.itemsSelected = [...this.itemsSelected, data];
				let filterNotSubmittedExpenses = this.notSubmitedExpenses.filter(
					(item) => +item.amountTotal !== 0 && +item.validated !== 16 && +item.validated !== 4
				);
				this.itemsSelected.length === filterNotSubmittedExpenses.length ? (this.isSelectAll = true) : (this.isSelectAll = false);
			} else {
				let filterNotSubmittedExpenses = this.notSubmitedExpenses.filter((item) => item.validated !== 16 && item.validated !== 4);
				this.itemsSelected = this.itemsSelected.filter((item) => item !== data);
				filterNotSubmittedExpenses.length !== this.itemsSelected.length ? (this.isSelectAll = false) : (this.isSelectAll = true);
			}
			return this.itemsSelected;
		},
		async initData() {
			this.isLoading = true;
			let activateCrewAccessRestriction = true;
			this.notSubmitedExpenses = [...(await getExpenseItemsNotSubmitted(activateCrewAccessRestriction))].map((item) => {
				return { ...item.expenseItem, crew_member: item.expenseItem.user.name + ' ' + item.expenseItem.user.firstName };
			});
			this.submitedExpenses = [...(await getExpenseItemsSubmitted(activateCrewAccessRestriction))].map((item) => {
				return { ...item.expense, crew_member: item.expense.user.name + ' ' + item.expense.user.firstName };
			});
			this.isLoading = false;
		},
		tabOneCaption() {
			return this.isFilmSingle ? this.FormMSG(126, 'Expense items') : this.FormMSG(127, 'Expense items');
		},
		tabTwoCaption() {
			return this.isFilmSingle ? this.FormMSG(121, 'Expense sheets') : this.FormMSG(125, 'Submitted expense sheets');
		},
		async handleModalSubmited(e) {
			this.expenseModalRefreshToken = this.expenseModalRefreshToken + 1;
			this.$toast({
				message: this.FormMSG(69, 'Your expense was saved successfully.'),
				title: this.FormMSG(162, 'Success'),
				variant: 'success'
			});
			this.isNewExpenseModalOpen = false;
      this.klippaModalRefreshKey = makeID(10);
			this.initData();
		},
    handleKlippaModalRefreshKey() {
      this.klippaModalRefreshKey = makeID(10);
    },
		async reloadData() {
			this.initData();
		},
		/**
		 * @param {Object} expense
		 */
		openEditExpense(expense) {
			if (expense.type === 2) {
				expense.originalKm = expense.km;
				expense.originalFromLocation = expense.fromLocation;
				expense.originalToLocation = expense.toLocation;
			} else {
				expense.originalImages = expense.images;
			}
			this.newExpenseType = expense.type;
			this.currEditExpense = {
				...expense,
				images: expense.images,
				statusClass: this.classStatus(expense.validated),
				typeString: this.setType(this.newExpenseType),
				validatedStatus: this.getLabelStatus(expense.validated)
			};
			this.isNewExpenseModalOpen = true;
			this.isNew = false;
		},

    /**
     * @param {Object} change
     */
    handleCurrExpenseChanged(change) {
      this.currEditExpense = _.merge(this.currEditExpense, change || {});
    },

		/**
		 * @param {Array} images
		 */
		showPicture(images) {
			if (isNil(images)) return;

			const list = images.map((img) => ({
				xid: img,
				src: `${process.env.VUE_APP_GQL}/images/${img}`,
				thumb: process.env.VUE_APP_PATH_IMG_THUMB_URL + img,
				ext: getFileExtension(img)
			}));

			this.$previewImages({
				images: list,
				focusIndex: 0,
				options: {
					hideLikeDislike: true,
					hideCommentButton: true,
					hideDisLikeButton: true,
					presentationMode: 'doc'
				}
			});
		},
		/**
		 * @param {Object} item - expenseClicked
		 */
		rowClicked(item) {
			this.getCurExpense(parseInt(item.id, 10));
			this.setFocusExpenseItem(item);
			const tsLink = window.location.href.split`#/`[1] + '/' + item.id;
			this.$router.push({ path: `/expensesEntryCrew/${item.id}` });
		},
		viewExpenseDatailMobile(item, isHome) {
			this.getCurExpense(parseInt(item.id, 10));
			this.setCurrentExpenseMobile(item);
			const tsLink = window.location.href.split`#/`[1] + '/' + item.id;
			this.$router.push({ path: tsLink });
		},
		// -----------------------------------------
		// ------------- MODALS --------------------
		// -----------------------------------------
		openExpenseModal(type, isNew) {
			this.$nextTick(() => {
				this.currEditExpense = null;
				this.newExpenseType = type;
				this.isNew = isNew;
				this.isNewExpenseModalOpen = true;
			});
		},
		checkFormValidity() {
			const valid = this.$refs.form.checkValidity();
			this.newExpense.nameState = valid;
			return valid;
		},
		/**
		 * @param {Object} expense
		 */
		async handleDeleteExpense(expense, showSuccessDialog = true) {
			if (isNil(expense)) return;

			const index = this.notSubmitedExpenses.indexOf(expense);
			const txt = this.FormMSG(98981, 'Please confirm that you want delete this expense');

			if (showSuccessDialog === true) {
				this.$bvModal
					.msgBoxConfirm(txt, {
						title: this.rendConfirmModalTxt.title,
						size: 'sm',
						buttonSize: 'sm',
						okVariant: 'danger',
						okTitle: this.rendConfirmModalTxt.okTitle,
						cancelTitle: this.rendConfirmModalTxt.cancelTitle,
						footerClass: 'p-2',
						hideHeaderClose: false,
						centered: false
					})
					.then(async (v) => {
						if (!v) return;
						else {
							this.notSubmitedExpenses.splice(index, 1);
							let result = await deleteExpenseEfc(expense.id);
							return result;
						}
					})
					.catch((err) => console.log(err));
			} else {
				this.notSubmitedExpenses.splice(index, 1);
				let result = await deleteExpenseEfc(expense.id);
				return result;
			}
		},
		handSendAllExpensesValidation() {
			if (this.isFilmSingle) {
				this.sendToValidation();
				return;
			}
			this.boxTwo = '';
			let size = this.itemsSelected.length;
			let all = size > 1 ? this.FormMSG(334, 'All ') : ' ';
			let s = size > 1 ? this.FormMSG(336, 's ') : ' ';
			this.$bvModal
				.msgBoxConfirm(
					this.FormMSG(335, `Please confirm that you want to send `) +
						all +
						this.FormMSG(337, `unsent expense`) +
						s +
						this.FormMSG(338, `to the validation`),
					{
						title: this.rendConfirmModalTxt.title,
						size: 'sm',
						buttonSize: 'sm',
						okVariant: 'primary',
						okTitle: this.rendConfirmModalTxt.okTitle,
						cancelTitle: this.rendConfirmModalTxt.cancelTitle,
						footerClass: 'p-2',
						hideHeaderClose: false,
						centered: false
					}
				)
				.then((value) => {
					if (value) {
						this.isOpenTitleModalPC = true;
					}
				})
				.catch((err) => console.log(err));
		},
		onCancelTitleModalPC(payload) {
			this.isOpenTitleModalPC = payload;
		},
		async onAddTitleModalPC(title) {
			let expenseTitle = title;
			let expenseData = [...this.itemsSelected].map((item) => {
				return { expenseItemId: +item.id, type: +item.type };
			});
			let result = await sendSelectedExpensesForValidation(expenseTitle, expenseData);
			if (result) {
				var strTitle = this.FormMSG(162, 'Success');
				this.$bvToast.toast(this.FormMSG(161, 'Your expenses were sent for validation'), {
					title: strTitle,
					variant: 'success',
					solid: true,
					toaster: 'b-toaster-top-center',
					autoHideDelay: 3000
				});
				this.isOpenTitleModalPC = false;
				this.reinitializeTitle = true;
				this.initData();
				this.itemsSelected = [];
				this.isSelectAll = false;
			} else {
				var strTitle = this.FormMSG(47, 'Error');
				this.$bvToast.toast(this.FormMSG(48, 'Your expenses were not sent for validation'), {
					title: strTitle,
					variant: 'danger',
					solid: true,
					toaster: 'b-toaster-top-center',
					autoHideDelay: 3000
				});
				this.isOpenTitleModalPC = false;
				this.reinitializeTitle = true;
				this.initData();
				this.itemsSelected = [];
				this.isSelectAll = false;
			}
		},
		setStatusExpenseDefault(value) {
			this.expenseStatusDefault = value;
		},
		getExpenseList(status) {
			this.handleTabChanged(status);
			if (status === 0) {
				this.notSubmittedState = true;
				this.submittedState = false;
				return (this.expenses = this.notSubmitedExpenses);
			} else {
				this.notSubmittedState = false;
				this.submittedState = true;
				return (this.expenses = this.submitedExpenses);
			}
		},
		rendAmountTotal(amountTotal) {
			return rendCurrency(amountTotal);
		},
		dateToLiteral(expenseDate) {
			const langCurrent = this.currentLang;
			const fullLiteralDate = moment(expenseDate).locale(`${langCurrent}`).format(`dddd, Do MMMM YYYY`);

			return fullLiteralDate;
		},
		rendCo2(kgCo) {
			return rendKgCo2(kgCo);
		},
		switchCheckAll() {
			// action before
			if (this.checkAll) {
				for (let i = 0; i < this.notSubmittedCopyIds.length; i++) {
					this.notSubmittedIds.push(parseInt(this.notSubmittedCopyIds[i], 10));
					document.getElementById(`${this.generateTextId('card-expense-' + this.notSubmittedCopyIds[i])}`).style.border = '1px solid #225CBD';
				}
				// console.log({notSubmittedIds: this.notSubmittedIds});
			} else {
				const ids = this.notSubmittedIds;
				for (let i = 0; i < ids.length; i++) {
					if (document.getElementById(`${this.generateTextId('card-expense-' + ids[i])}`)) {
						document.getElementById(`${this.generateTextId('card-expense-' + ids[i])}`).style.border = 'none';
					}
				}
				this.notSubmittedIds = [];
			}

			// end
			this.checkAll = !this.checkAll;
		},
		setSelectedItem(payload, item, id) {
			// is checked
			if (payload.findIndex((p) => p === item.id) > -1) {
				document.getElementById(`${id}`).style.border = '1px solid #225CBD';
			} else {
				document.getElementById(`${id}`).style.border = 'none';
			}

			this.checkAll = !(payload.length > 0);
		},
		generateTextId(id) {
			return generateSecureId(id);
		},
		editExpenseMobile(item) {
			let type = item.type;
			let form = 0;
			if (item.type === 0 || item.type === 1) {
				form = 2;
			} else if (item.type === 2) {
				form = 1;
				item.originalKm = item.km;
				item.originalFromLocation = item.fromLocation;
				item.originalToLocation = item.toLocation;
			}
			this.setFocusExpenseItem(item);

			const path = `/myexpenses/${form}/u/${type}/${parseInt(item.id, 10)}`;
			this.$router.push({ path });
		},
		deleteExpenseMobile(id) {
			const txt = this.FormMSG(98981, 'Please confirm that you want delete this expense');

			this.$bvModal
				.msgBoxConfirm(txt, {
					title: this.rendConfirmModalTxt.title,
					size: 'sm',
					buttonSize: 'sm',
					okVariant: 'danger',
					okTitle: this.rendConfirmModalTxt.okTitle,
					cancelTitle: this.rendConfirmModalTxt.cancelTitle,
					footerClass: 'p-2',
					hideHeaderClose: false,
					centered: false
				})
				.then(async (v) => {
					if (!v) return;
					this.notSubmittedCopyIds.splice(
						this.notSubmittedCopyIds.findIndex((expenseId) => expenseId === id),
						1
					);
					this.expenses.splice(
						this.expenses.findIndex((expense) => expense.id === id),
						1
					);
					// console.log('deleteExpenseMobile: ', this.expenses.length);
					await this.deleteExpenseEfc(id);
				})
				.catch((err) => console.log(err));
		},
		handleSendExpensesForValidationMobile() {
			this.itemsSelected = [];

			const itemsSelected =
				this.notSubmittedIds.length === this.notSubmittedCopyIds.length
					? this.notSubmitedExpenses.filter((expense) => expense.amountTotal !== 0 && expense.validated !== 4 && expense.validated !== 16)
					: this.notSubmitedExpenses.filter((expense) => this.notSubmittedIds.includes(expense.id));

			itemsSelected.forEach((expense) => {
				this.itemsSelected.push({
					expenseItemId: parseInt(expense.id, 10),
					type: parseInt(expense.type, 10)
				});
			});

			if (this.isFilmSingle) {
				this.isOpenTitleModal = true;
			} else {
				this.$bvModal
					.msgBoxConfirm(this.FormMSG(39, 'Please confirm that you want to send expense(s) for validation.'), {
						title: this.FormMSG(40, 'Confirm'),
						size: 'sm',
						buttonSize: 'sm',
						okVariant: 'primary',
						cancelVariant: 'light',
						okTitle: this.FormMSG(41, 'Send'),
						cancelTitle: this.FormMSG(42, 'Cancel'),
						footerClass: 'p-2',
						hideHeaderClose: true,
						centered: false,
						noCloseOnBackdrop: true
					})
					.then((value) => {
						if (value) {
							this.isOpenTitleModal = true;
						} else {
							this.checkAll = false;
							this.switchCheckAll();
						}
					})
					.catch((err) => {
						// An error occurred
					});
			}
		},
		onCancelTitleModal(payload) {
			this.checkAll = false;
			this.switchCheckAll();
			this.isOpenTitleModal = payload;
		},
		async onAddTitleModal(payload) {
			await sendSelectedExpensesForValidation(payload, this.itemsSelected)
				.then(async (result) => {
					if (result) {
						this.createToastForMobile(this.FormMSG(162, 'Success'), this.FormMSG(47, 'Your expenses were sheet has been created.'));
						await this.initData();
						this.notSubmittedIds = [];
						this.checkAll = true;
					} else {
						this.createToastForMobile(
							this.FormMSG(125, 'Error'),
							this.FormMSG(126, 'An error was occured during validate your expense(s), please contact your administrator.'),
							'',
							'danger'
						);
					}
				})
				.catch((error) => {
					console.error({ error });
				})
				.finally(() => {
					this.isOpenTitleModal = false;
					this.reinitializeTitle = true;
				});
		},
		showExpenseTitle(expense) {
			return this.submittedState && expense.description.replace(/[ \s]+/g, '') !== '' && expense.description.replace(/[ \s]+/g, '') !== `#${expense.id}`;
		},
		async onCloseExpenseForm(payload) {
			if (payload) {
				// await this.initData();
			}
		},
		recalculateDistanceByUnit(value) {
			return recalculateDistanceValueByUnit(value);
		},
		getLabelStatus(status) {
			return this.GetTextFromMenuNumberAndMenuValue(1008, status);
		},
		setType(v) {
			if (v === 0) return 'Ticket';
			if (v === 1) return 'Invoice';
			if (v === 2) return 'Travel';
		},
		getError(payload) {
			if (!_.isNil(payload) && !_.isNil(payload.status) && payload.status === 509) {
				this.isNewExpenseModalOpen = true;
			}
		}
	}
};
</script>

<style>
.table-large-body {
	min-height: 480px;
}
.expense-mobile-icon-list {
	padding: 0 !important;
}
.expense-mobile-icon-list .label {
	font-size: 0.8rem;
	line-height: 1.1;
}
.my-expense-mobile-view-component-wrapper {
	margin-top: 1.5rem;
}
.spacing-screen {
	margin-left: -10px;
	margin-right: -10px;
}
.position {
	padding: 14px;
	border-radius: 50%;
}
.border-style {
	border-radius: 10px;
}
.spacing-pad {
	padding: 10px;
}
</style>
